<template>
  <section class="customer-comments">
    <!-- Bu sayfanın html ve css yapı daha sade yazılmalı -->
    <v-container class="mx-auto">
      <v-row
        no-gutters
        class="flex-column align-center align-lg-start flex-lg-row"
      >
        <!-- Rating container starts -->
        <v-row
          no-gutters
          class="feedback-container flex-column justify-space-between"
        >
          <h2 class="feedback--h2">{{ mainTitle }}</h2>
          <v-row
            no-gutters
            class="customer-comments--feedback flex-column justify-center align-center"
          >
            <h3 class="customer-comments--feedback--title">Genel Puanı</h3>
            <v-rating
              class="customer-comments--feedback--rating"
              color="white"
              length="5"
              dense
              size="20"
              :value="4.5"
              half-increments
              readonly
            ></v-rating>
            <!-- <p>4.4</p> -->
          </v-row>
        </v-row>
        <!-- Rating container ends -->
        <!-- Slides start -->
        <v-col v-if="$route.name !== 'ForeignHealthInsurance'" cols="12" sm="12" lg="7" class="pa-0 mt-4 mt-lg-0">
          <app-carousel :options="options">
            <splide-slide v-for="slide in slides" :key="slide.id">
              <v-card
                class="customer-comments--card d-flex flex-column"
                height="300"
              >
                <v-rating
                  color="green"
                  class="mb-6"
                  length="5"
                  dense
                  size="20"
                  :value="slide.rating"
                  half-increments
                  readonly
                ></v-rating>
                <p class="customer-comments--card-name">
                  {{ slide.userName }}
                </p>
                <h3 class="customer-title-h3 mt-2">{{ slide.title }}</h3>
                <p class="mt-2 customer-comments--card--desc">
                  {{ slide.desc }}
                </p>
                <v-btn
                  text
                  plain
                  class="align-self-end"
                  @click="showModal(slide)"
                >
                  <i class="fas fa-arrow-right fa-lg"></i>
                </v-btn>
              </v-card>
            </splide-slide>
          </app-carousel>
        </v-col>
         <v-col v-else cols="12" sm="12" lg="7" class="pa-0 mt-4 mt-lg-0">
          <app-carousel :options="options">
            <splide-slide v-for="foreignComment in foreignComments" :key="foreignComment.id">
              <v-card
                class="customer-comments--card d-flex flex-column"
                height="300"
              >
                <v-rating
                  color="green"
                  class="mb-6"
                  length="5"
                  dense
                  size="20"
                  :value="foreignComment.rating"
                  half-increments
                  readonly
                ></v-rating>
                <p class="customer-comments--card-name">
                  {{ foreignComment.userName }}
                </p>
                <v-row class="align-center">
                <i class="fab fa-google fa-sm foreignColor"></i>
                <h3 class="customer-title-h3 ml-3">{{ foreignComment.title }}</h3>
                </v-row>
                <p class="mt-2 customer-comments--card--desc">
                  {{ foreignComment.desc }}
                </p>
                <v-btn
                  text
                  plain
                  class="align-self-end"
                  @click="showModal(slide)"
                >
                  <i class="fas fa-arrow-right fa-lg"></i>
                </v-btn>
              </v-card>
            </splide-slide>
          </app-carousel>
        </v-col>
        <!-- Slides end -->
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card
        elevation="24"
        outlined
        v-if="selectedCustomer"
        class="customer-comments--card d-flex flex-column"
      >
        <v-rating
          color="green"
          class="mb-6"
          length="5"
          dense
          size="20"
          :value="selectedCustomer.rating"
          half-increments
          readonly
        ></v-rating>
        <p class="customer-comments--card-name success--text">
          <b> {{ selectedCustomer.userName }}</b>
        </p>
        <h4 class="mt-2">{{ selectedCustomer.title }}</h4>
        <p class="mt-2">
          {{ selectedCustomer.desc }}
        </p>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import appCarousel from '@/component/carousel/AppCarousel'
export default {
  components: {
    appCarousel
  },
  props: {
    mainTitle: {
      type: String,
      required: false,
      default: '  Müşterilerimizden Harika Geri Bildirimler'
    }
  },
  data () {
    return {
      dialog: false,
      selectedCustomer: null,
      options: {
        rewind: true,
        autoplay: true,
        interval: 4000,
        speed: 3000,
        height: '25rem',
        arrows: false,
        perPage: 2,
        gap: '1rem',
        breakpoints: {
          550: {
            perPage: 1,
            height: '30rem'
          }
        }
      },
      slides: [
        {
          id: 1,
          userName: 'KEREM GÜNGÖR',
          rating: 5,
          title: 'Sağlığımız Onlara Emanet;',
          desc:
            'Merhaba, Eşim için sağlık sigortası yaptırdığım süreçte benimle ilgilenen Canberk Bey’e teşekkür ederim. Böyle kaliteli ve güler yüzlü hizmet veren personellerle çalışan siz Alokasko.com ailesine iyi çalışmalar diler, saygılarımı sunarım.'
        },
        {
          id: 2,
          userName: 'SEVGİ ÇELEBİ',
          rating: 4.5,
          title: 'Sizi Neden Sevdim;',
          desc:
            'Hem kasko hem de trafik sigortam konusunda bıkmadan yılmadan her soruma itinayla cevap veren ve en iyi fiyat konusunda elinden geleni yapan Alokasko.com çalışanlarına çok teşekkür ederim.'
        },
        {
          id: 3,
          userName: 'AYÇA KAYA',
          rating: 4.5,
          title: 'Karar Veremediğim Her An;',
          desc:
            'Gerek kasko gerekse trafik sigortamı Alokasko.com güvencesi ile yaptırıyorum. Müşteri temsilcilerinin verdikleri bilgiler doğrultusunda en iyi sigortayı en uygun fiyat ile almaktayım. '
        },
        {
          id: 4,
          userName: 'POLAT ÇELİK',
          rating: 5,
          title: 'Fiyat Odaklı Olmamanızı Seviyorum;',
          desc:
            'Yıllardır çalışmış olduğum acentemden daha fazla ilgi ve alaka gösterdiniz teşekkür ederim.'
        },
        {
          id: 5,
          userName: 'YASİN TİMUR',
          rating: 4.5,
          title: 'Zamandan Kurtulduk;',
          desc:
            'Hem araç sigortalarımızda hem de sağlık sigortalarımızda yanımızda olan alokasko.com ailesine çok teşekkür ediyorum.'
        },
        {
          id: 6,
          userName: 'MUKADDES GÜL',
          rating: 4.5,
          title: 'Araçsız Kalsam Dahi Sıkıntıya Düşmedim;',
          desc:
            'Aracımın tamirinin yapılması konusunda, sorunu çözmek için elinden geleni yapan ve çözen alokasko.com ailesine teşekkür ederim.'
        },
        {
          id: 7,
          userName: 'İBRAHİM KURT',
          rating: 5,
          title: 'Sorun Yaşamadığım İçin Memnumun;',
          desc:
            'Yapmış olduğum kaza sonucunda oluşan ikame araç sorununu hızlıca çözüme kavuşturmama yardımcı olan alokasko.com hasar departmanına çok teşekkür ederim.'
        },
        {
          id: 8,
          userName: 'HALİL TAŞ',
          rating: 4.5,
          title: 'İlk Aracımda Kaza Beklemiyordum;',
          desc:
            'İlk kez aracım oldu ve sigortalarımı alokasko.com üzerinden yaptırdım. Fakat hasar anında sorun çıkacak diye endişelenirken hasar anında ve hasar sürecinde benimle kontağı hiç kesmeyip yanımda olan alokasko.com hasar departmanı çalışanlarına teşekkür ederim.'
        },
        {
          id: 9,
          userName: 'AYŞE KAPLAN ',
          rating: 4.5,
          title: 'Öneri İle Tanıştım, Şimdi Herkese Öneriyorum;',
          desc:
            'Yıllardır çalışmış olduğum acentenin ihmali sonucunda trafik sigortamı yenilemeyi unuttum ve kaza yaşadım bu kaza büyük olmamasında dolayı çok fazla bir kayıp yaşamadım. Bu olayı arkadaşıma anlattığım zaman alokasko.com’u önerdi. 2 yıldır sigortamı alokasko.com üzerinde yapıyorum. Poliçem bitmeden önce beni arayıp bilgilendiriyorlar. Teşekkür ederim alokasko.com'
        },
        {
          id: 10,
          userName: 'SAMİ İBRAHİM ÇELİKKOL',
          rating: 4.5,
          title: 'İlgisiz Asla;',
          desc:
            'Alokasko ailesine yeni katıldım. Müşteri memnuniyeti adına gösterdiğiniz çaba muhteşemdi. İlginiz ve yardım çabalarınız beni çok memnun etti.'
        },
        {
          id: 11,
          userName: 'AYŞEGÜL ERTUĞRUL',
          rating: 4.5,
          title: 'Artık Her Detaya Hâkimim;',
          desc:
            'Alacağım poliçeyle ilgili beni en ince ayrıntıya kadar bilgilendiren ve en güzel şekilde yönlendiren alokaskoya çok teşekkür ediyorum. Üyeniz olmaktan mutluluk duyuyorum.'
        },
        {
          id: 12,
          userName: 'KAMİL ALPTEKİN',
          rating: 4.5,
          title: 'Teşekkürler;',
          desc:
            "Alokasko'nun verdiği destekleri herhangi bir acenteden alamayacağımı biliyorum. Bundan sonraki poliçelerimi de hiç çekinmeden Alokasko üzerinden yaptıracağım."
        },
        {
          id: 13,
          userName: 'İNCİ GÜLTEKİN',
          rating: 4.5,
          title: 'Sorunsuz Poliçem İle Mutluyum;',
          desc:
            'Kasko poliçemi Alokasko üzerinden yaptırdım. Son derece ilgili ve çözüm odaklı yaklaşımları ve bulduğum en uygun fiyatlar olması sebebiyle son derece memnun kaldım. Teşekkür ederim.'
        }
      ],
      foreignComments: [
        {
          id: 1,
          userName: 'Avlod Razamova',
          rating: 5,
          title: 'Google Satisfaction Rating',
          desc: 'ular menga juda yaxshi g`amxo`rlik qilishdi, ishonchli kompaniya'
        },
        {
          id: 2,
          userName: 'Androre Mahmudov',
          rating: 5,
          title: 'Google Satisfaction Rating',
          desc: 'Kompaniya meni ofisida juda yaxshi kutib oldi, rahmat.'
        },
        {
          id: 3,
          userName: 'Aziz Yunusov',
          rating: 5,
          title: 'Google Satisfaction Rating',
          desc: 'Ajoyib kompaniya mening sug`urta muammomni 2 daqiqada hal qildi'
        },
        {
          id: 4,
          userName: 'Asmir',
          rating: 5,
          title: 'Google Satisfaction Rating',
          desc: 'Men ishonchli kompaniyadan juda mamnunman'
        },
        {
          id: 5,
          userName: 'Guldasta',
          rating: 5,
          title: 'Google Satisfaction Rating',
          desc: 'siz juda yaxshisiz, hamma narsa uchun rahmat'
        },
        {
          id: 6,
          userName: 'Aden',
          rating: 5,
          title: 'Google Satisfaction Rating',
          desc: 'شكرا على كل شيء شركة رائعة!'
        },
        {
          id: 7,
          userName: 'Amine',
          rating: 5,
          title: 'Google Satisfaction Rating',
          desc: 'أنا مرتاح جدا لهذه الشركة'
        },
        {
          id: 8,
          userName: 'Ceylin',
          rating: 5,
          title: 'Google Satisfaction Rating',
          desc: 'أنا راضٍ جدًا عن الخدمة التي تلقيتها ، مكتبك وزملائك في الفريق يقدمون خدمة جيدة جدًا.'
        },
        {
          id: 9,
          userName: 'Rishi',
          rating: 5,
          title: 'Google Satisfaction Rating',
          desc: 'Я очень доволен полученным обслуживанием, ваш офис и ваши товарищи по команде предоставляют очень хороший сервис.'
        },
        {
          id: 10,
          userName: 'Pratham',
          rating: 5,
          title: 'Google Satisfaction Rating',
          desc: 'надежная компания'
        },
        {
          id: 11,
          userName: 'Prokhop',
          rating: 5,
          title: 'Google Satisfaction Rating',
          desc: 'Я купил у вас раньше, и я до сих пор очень доволен.'
        },
        {
          id: 12,
          userName: 'Roshan',
          rating: 5,
          title: 'Google Satisfaction Rating',
          desc: 'спасибо тебе большое за все ты супер'
        },
        {
          id: 13,
          userName: 'Nick',
          rating: 5,
          title: 'Google Satisfaction Rating',
          desc: 'thank you so much for everything you are great'
        },
        {
          id: 14,
          userName: 'Arthur',
          rating: 5,
          title: 'Google Satisfaction Rating',
          desc: 'I recommend you to see the address in Istanbul, it is a great company'
        },
        {
          id: 15,
          userName: 'Adam',
          rating: 5,
          title: 'Google Satisfaction Rating',
          desc: 'A company that you can buy the same insurance from other companies at very affordable prices, they are really good.'
        },
        {
          id: 16,
          userName: 'Jesse',
          rating: 5,
          title: 'Google Satisfaction Rating',
          desc: 'Your coffee and your team are very nice people'
        }
      ]
    }
  },
  methods: {
    showModal (slide) {
      this.selectedCustomer = slide
      this.dialog = true
    }
  },
  watch: {
    dialog () {
      if (this.dialog === false) this.selectedCustomer = {}
    }
  }
}
</script>
<style scoped>
.foreignColor {
  color: green;
}
</style>
