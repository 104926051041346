<template>
  <div></div>
  <!-- <section class="ak-blog">
    <v-container class="mx-auto">
      <v-row no-gutters class="justify-center flex-column ">
        <h2 class="pt-10 section-title">{{ mainTitle }}</h2>
        <v-row
          no-gutters
          class=" ak-blog-title-area flex-column flex-md-row align-center justify-md-space-between"
        >
          <div class="d-none d-md-block"></div>
          <p class="mt-2 all-quote">
            {{ titleDesc }}
          </p>
          <router-link class="all-blogs-link" to="/blog"
            >Tüm Bloglar</router-link
          >
        </v-row>
      </v-row>
    </v-container>
    <v-container class="mx-auto">
      <div class="mt-10"> -->
  <!-- BLOGLAR DINAMIC HALE GETİRELECEK -->
  <!-- <app-carousel :options="options">
          <splide-slide v-for="(slide, i) in slides" :key="i">
            <v-col cols="12">
              <v-card class="ak-blog__card" elevation="4">
                <v-img height="286" width="100%" :src="slide.image"></v-img>

                <div class="ak-blog__card-content d-flex flex-column">
                  <h3 class="ak-blog__card-title">
                    {{ slide.title }}
                  </h3>

                  <p class="ak-blog__card-text">
                    {{ slide.desc }}
                  </p>
                  <v-btn
                    text
                    plain
                    class="align-self-end"
                    @click="$router.push('/blog-detay')"
                  >
                    <i class="fas fa-arrow-right fa-lg"></i>
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </splide-slide>
        </app-carousel>
      </div>

      <slot></slot>
    </v-container>
  </section> -->
</template>

<script>
// import appCarousel from '@/component/carousel/AppCarousel'
export default {
  components: {
    // appCarousel
  },
  props: {
    mainTitle: {
      type: String,
      required: false,
      default: 'Alokasko Blog'
    },
    titleDesc: {
      type: String,
      required: false,
      default: 'Her gün yayınlanan güvenilir bilgilere kolay erişim.'
    }
  },
  data () {
    return {
      options: {
        type: 'slide',
        rewind: true,
        height: '37rem',
        lazyload: 'nearby',
        arrows: false,
        autoplay: true,
        interval: 4000,
        gap: '1rem',
        perPage: 3,
        breakpoints: {
          550: {
            height: '45rem',
            perPage: 1
          },
          650: {
            perPage: 1
          },
          1024: {
            perPage: 2,
            height: '39rem'
          }
        }
      },
      slides: [
        {
          image: require('@/assets/temp/card-images/blog1.svg'),
          title: 'Özel Sağlık Sigortası Nedir?',
          desc:
            'Günlük hayatımızda karşılaştığımız hastalık veya kaza sonucunda gerekli olan tahlil, tetkik ve tedavileri karşılar.'
        },

        {
          image: require('@/assets/temp/card-images/blog2.svg'),
          title: 'Tamamlayıcı Sağlık Sigortası Nedir?',
          desc:
            'Tamamlayıcı Sağlık Sigortası, SGK (Sosyal Güvenlik Kurumu) kapsamında yer alan kişilerin hem kendilerini,..'
        },

        {
          image: require('@/assets/temp/card-images/blog3.svg'),
          title: '2021 Ortalama Özel Sağlık Fiyatları Ne Kadar?',
          desc:
            '2020 yılında medikal alanda fiyat 020 yılında medikal alanda fiya020 yılında medikal alanda fiya020 yılında medikal alanda fiyaartışı gözle görülebilir seviyede arttı.'
        },
        {
          image: require('@/assets/temp/card-images/blog3.svg'),
          title: 'Özel Sağlık Sigortası Nedir?',
          desc:
            'Günlük hayatımızda karşılaştığımız hastalık veya kaza sonucunda gerekli olan tahlil, tetkik ve tedavileri karşılar.'
        },
        {
          image: require('@/assets/temp/card-images/blog1.svg'),
          title: 'Özel Sağlık Sigortası Nedir?',
          desc:
            'Günlük hayatımızda karşılaştığımız hastalık veya kaza sonucunda gerekli olan tahlil, tetkik ve tedavileri karşılar.'
        },

        {
          image: require('@/assets/temp/card-images/blog2.svg'),
          title: 'Tamamlayıcı Sağlık Sigortası Nedir?',
          desc:
            'Tamamlayıcı Sağlık Sigortası, SGK (Sosyal Güvenlik Kurumu) kapsamında yer alan kişilerin hem kendilerini,..'
        },

        {
          image: require('@/assets/temp/card-images/blog3.svg'),
          title: '2021 Ortalama Özel Sağlık Fiyatları Ne Kadar?',
          desc:
            '2020 yılında medikal alanda fiyat artışı gözle görülebilir seviyede arttı.'
        },
        {
          image: require('@/assets/temp/card-images/blog3.svg'),
          title: 'Özel Sağlık Sigortası Nedir?',
          desc:
            'Günlük hayatımızda karşılaştığımız hastalık veya kaza sonucunda gerekli olan tahlil, tetkik ve tedavileri karşılar.'
        }
      ]
    }
  }
}
</script>
