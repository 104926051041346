<template>
  <div>
    <splide :options="options">
      <slot></slot>
    </splide>
  </div>
</template>

<script>
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css'
export default {
  props: {
    options: {
      type: Object,
      required: false
    }
  }
}
</script>
